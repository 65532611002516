<template>
  <piepkaart
    :minLoadDataZoom="10"
    @popupopen="onPopup"
    :legend="legenda"
    name="Kastelen"
  >
    <template v-slot="{ bounds, minLoadDataZoom }">
      <piepkaart-wfs-layer
        :bounds="bounds"
        :minLoadDataZoom="minLoadDataZoom"
        wfs-url="https://services.rce.geovoorziening.nl/landschapsatlas/wfs"
        type-name="landschapsatlas:kastelen"
        output-format="json"
        srs-name="EPSG:4326"
        :use-proxy="false"
        :onEachFeature="onEachFeature"
        :pointToLayer="pointToLayer"
        :minRefetchDistance="10"
      ></piepkaart-wfs-layer>
    </template>
  </piepkaart>
</template>
<script>
import Piepkaart from "@/components/Piepkaart.vue";
import PiepkaartWfsLayer from "@/components/PiepkaartWfsLayer.vue";
import L from "leaflet";
export default {
  name: "Kastelen",
  components: {
    Piepkaart,
    PiepkaartWfsLayer,
  },
  data() {
    return {
      legenda: [
        { color: "#388E3C", label: "Intact" },
        { color: "#D32F2F", label: "Restant" },
      ],
    };
  },
  methods: {
    pointToLayer(feature, latlng) {
      return L.circleMarker(latlng, {
        radius: 8,
        stroke: true,
        weight: 1,
        color: "#fff",
        fillColor:
          feature.properties.legenda === "Intact" ? "#388E3C" : "#D32F2F",
        fillOpacity: 0.8,
      });
    },
    onEachFeature(feature, layer) {
      if (feature.properties) {
        layer.bindPopup(
          `
          <div class="d-flex">
            <div class="mr-3">
              <img width="96" class="rounded" src="${feature.properties.foto_thumb}"/>
            </div>
            <div>
              <div style="font-weight: 600">${feature.properties.naam}</div>
              <div>Datering: ${feature.properties.datering}</div>
              <div>Staat: ${feature.properties.legenda}</div>
              <div>Fotograaf: ${feature.properties.bijschrift}</div>
              <div><a href="${feature.properties.info_link}" target="_blank" rel="noreferrer">Meer informatie</a></div>
            </div>
          </div>
            `,
          {
            closeButton: false,
            autoPan: false,
          }
        );
      }
    },
    onPopup(popup) {
      const { naam } = popup.feature.properties;
      window.gtag("event", "kastelen", {
        naam: naam,
      });
    },
  },
};
</script>